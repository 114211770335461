import { LegacyRef, useCallback, useState } from 'react';

export const useIsMounted = <T extends HTMLElement>() => {
  const [isMounted, setIsMounted] = useState(false);

  const ref: LegacyRef<T> = useCallback((el: T | null) => {
    if (el) {
      setIsMounted(true);
    }
  }, []);

  return { ref, isMounted };
};
