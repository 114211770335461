import { RouteComponentProps } from '@gatsbyjs/reach-router';
import React from 'react';
import { Helmet } from 'react-helmet-async';

import { Corporation } from './Corporation';
import { ItemList } from './ItemList';
import { ShopProfile } from './Profile';

import { useShopDetail } from '@api/shop/useShopDetail';
import { title } from '@components/layout/SEO';
import { PageContainer } from '@components/layout/page-container';
import { VStack } from '@components/layout/vstack';
import { useIsMounted } from '@hooks/useIsMounted';

type Props = {
  shopId?: string;
} & RouteComponentProps;

export const ShopDetail = ({ shopId }: Props) => {
  // client-only route はビルド時にパラメータが空でレンダリングされるため
  // hydration error が起きてしまう。それを回避するために
  const { ref, isMounted } = useIsMounted<HTMLDivElement>();
  const { shop } = useShopDetail(isMounted ? Number(shopId) : undefined);

  return (
    <PageContainer ref={ref}>
      {shop && (
        <Helmet>
          <title>
            {shop.name}のショップページ | {title}
          </title>
          <meta name="description" content={shop.description} />
        </Helmet>
      )}
      {shop && (
        <VStack spacing="100px">
          {/*<VStack spacing="" mb="25px" md={{ marginBottom: '100px' }}>
            <Banner src={shop.background?.url} />
            </VStack>
          */}
          <ShopProfile shop={shop} />
          <ItemList shopId={shop.id} />
          <Corporation shop={shop} />
          {/*
          <Collapse title={intl.formatMessage({ id: 'notice.items' })}>
            <NoticeComponent />
          </Collapse>
          */}
        </VStack>
      )}
    </PageContainer>
  );
};

// const Banner = styled.img`
//   width: 100vw;
//   height: 308px;
// `;
