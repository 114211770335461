import { useSuspenseFetch } from '../../hooks/useSuspenseFetch';
import { ErrorResponse } from '../../pb/api/error_pb';
import { Pagination } from '../../pb/api/v1/common/common_pb';
import { ListItemsRequest, ListItemsResponse } from '../../pb/api/v1/user/shop/shop_pb';
import { axiosClient } from '../axiosClient';

const pagination = new Pagination();
pagination.setPage(1);
pagination.setPerPage(12);

export const useShopItems = (shopId: number) => {
  const { data } = useSuspenseFetch<ListItemsResponse.Item.AsObject[], ErrorResponse>(
    ['item/list/shop', shopId],
    async () => {
      const req = new ListItemsRequest();
      req.setPagination(pagination);
      req.setShopid(shopId);

      const res = await axiosClient.post(`/api/v1/user/shop/item/list`, req.serializeBinary());
      return ListItemsResponse.deserializeBinary(res.data).toObject().itemsList;
    },
    { suspense: true, useErrorBoundary: true }
  );

  return { items: data };
};
