import React from 'react';

import { ItemCard } from './ItemCard';

import { useShopItems } from '@api/shop/useShopItems';
import { Container } from '@components/layout/container';
import { Grid } from '@components/layout/grid';

type Props = {
  shopId: number;
};

export const ItemList = ({ shopId }: Props) => {
  const { items } = useShopItems(shopId);

  return (
    <Container>
      <Grid
        gridTemplateColumns="repeat(2, 1fr)"
        rowGap="40px"
        columnGap="20px"
        gridTemplateRows="repeat(auto-fill, auto)"
        justify="center"
        md={{
          gridTemplateColumns: 'repeat(4, 1fr)',
        }}
      >
        {items.map((item) => (
          <ItemCard key={item.itemid} item={item} />
        ))}
      </Grid>
    </Container>
  );
};
