import React from 'react';
import styled from 'styled-components';

import { VStack } from '@components/layout/vstack';
import { Paragraph } from '@components/typographies/paragraph';
import { Shop } from '@pb/api/v1/user/shop/shop_pb';

type Props = {
  shop: Shop.AsObject;
};

export const Corporation = ({ shop }: Props) => {
  return (
    <VStack width="100%" align="flex-start" spacing="24px" mb="70px">
      <Heading>基本情報</Heading>
      <Row>
        <label>販売業者</label>
        <Paragraph>{shop.corporation?.name}</Paragraph>
      </Row>
      <Row>
        <label>店舗責任者</label>
        <Paragraph>
          {shop.storeManager?.lastName} {shop.storeManager?.firstName}
        </Paragraph>
      </Row>
      <Row>
        <label>住所</label>
        <Paragraph>
          {shop.corporation?.zipCode}&nbsp;
          {shop.corporation?.prefecture}&nbsp;
          {shop.corporation?.municipality}&nbsp;
          {shop.corporation?.address1}&nbsp;
          {shop.corporation?.address2}
        </Paragraph>
      </Row>
    </VStack>
  );
};

const Heading = styled.h2`
  background-color: var(--color-bg-skin);
  border-radius: var(--border-radius-md);
  height: 40px;
  font-size: 18px;
  font-weight: 700;
  padding-inline: 26px;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 70px 1fr;
  gap: 32px;

  > label {
    font-weight: normal;
  }
`;
