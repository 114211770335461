import styled, { CSSObject } from 'styled-components';

type Props = {
  height?: CSSObject['height'];
  width?: CSSObject['width'];
  margin?: CSSObject['margin'];
  pd?: CSSObject['padding'];
  border?: CSSObject['border'];
  bb?: CSSObject['border'];
  px?: CSSObject['padding'];
  py?: CSSObject['padding'];
};

export const Container = styled.div<Props>((_) => ({
  width: _.width,
  height: _.height,
  margin: _.margin,
  padding: _.pd,
  paddingLeft: _.px ?? _.pd,
  paddingRight: _.px ?? _.pd,
  paddingTop: _.py ?? _.pd,
  paddingBottom: _.py ?? _.pd,
  border: _.border,
  borderBottom: _.bb,
}));
