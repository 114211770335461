import React from 'react';
import styled from 'styled-components';

import { Flex } from '@components/layout/flexbox';
import { VStack } from '@components/layout/vstack';
import { breakpoint } from '@constants/theme';
import { useDetectMedia } from '@hooks/useDetectMedia';
import { Shop } from '@pb/api/v1/user/shop/shop_pb';

type Props = {
  shop: Shop.AsObject;
};

export const ShopProfile = ({ shop }: Props) => {
  const { md } = useDetectMedia();

  return (
    <VStack spacing={md ? '32px' : '20px'}>
      <Flex gap={md ? '22px' : '15px'}>
        <ShopIconWrapper>
          <ShopIcon src={shop.logo?.url} />
        </ShopIconWrapper>
        <ShopName>{shop.name}</ShopName>
      </Flex>
      <Description>{shop.description}</Description>
    </VStack>
  );
};

const ShopIconWrapper = styled.div`
  width: 120px;
  height: 120px;
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border-gray2);
  @media (max-width: ${breakpoint.md}px) {
    width: 88px;
    height: 88px;
  }
`;

const ShopIcon = styled.img`
  object-fit: contain;
  width: 120px;
  height: 120px;
  @media (max-width: ${breakpoint.md}px) {
    width: 88px;
    height: 88px;
  }
`;

const ShopName = styled.h1`
  font-size: 25px;
  font-weight: 600;
  @media (max-width: ${breakpoint.md}px) {
    font-size: 18px;
  }
`;

const Description = styled.p`
  font-size: 16px;
  text-align: left;

  @media (min-width: ${breakpoint.md}px) {
    text-align: center;
    max-width: 720px;
  }
  @media (max-width: ${breakpoint.md}px) {
    font-size: 12px;
  }
`;
