import React from 'react';
import styled from 'styled-components';

import { VStack } from '@components/layout/vstack';
import { Paragraph } from '@components/typographies/paragraph';
import { ListResponse } from '@pb/api/v1/user/item/item_pb';

type Props = {
  item: ListResponse.Item.AsObject;
};

export const ItemCard = ({ item }: Props) => {
  return (
    <VStack spacing="13px">
      <a href={`/items/${item.itemid}`}>
        <Image src={item.imagePath} />
      </a>
      <VStack mb="20px" width="100%" align="flex-start" spacing="8px">
        <ShopName>{item.shopName}</ShopName>
        <Paragraph>{item.itemName}</Paragraph>
        <Price>￥{item.price.toLocaleString()}</Price>
      </VStack>
    </VStack>
  );
};

const ShopName = styled.div`
  font-size: 13px;
  color: var(--color-gray2);
`;

const Price = styled.div`
  font-weight: bold;
`;

const Image = styled.img`
  aspect-ratio: 1;
  width: 100%;
  border-radius: var(--border-radius-md);
  object-fit: contain;
  border: 1px solid var(--color-border-gray2);
`;
