import { Router } from '@gatsbyjs/reach-router';
import React, { FC, Suspense } from 'react';

import { Layout } from '@components/layout';
import { Loading } from '@components/loading';
import { ShopDetail } from '@features/shop';

const ShopRoutePage: FC = () => {
  return (
    <Layout>
      <Suspense fallback={<Loading />}>
        <Router basepath="/shop">
          <ShopDetail path=":shopId" />
        </Router>
      </Suspense>
    </Layout>
  );
};

export default ShopRoutePage;
